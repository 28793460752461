import Container from "react-bootstrap/Container"
import Img from "gatsby-image"
import Layout from "components/layout"
import LocalizedLink from "components/localized-link"
import PropTypes from "prop-types"
import React from "react"
import SEO from "components/seo"
import { graphql } from "gatsby"
import useTranslations from "components/use-translations"

function IndirmeBox({
  iconImg,
  header,
  shortDescription,
  lastVersion,
  publishDate,
  programHref,
  buyHref,
  buyText,
  downloadHref,
  specstext,
  miniComputerIcon,
}) {
  const {
    genel: { indir },
    indirme: { sonSurum, yayinlanmaTarihi },
  } = useTranslations()
  return (
    <Container>
      <Container style={{ paddingTop: 40, paddingBottom: 40, maxWidth: 800 }}>
        <div className="d-flex flex-column flex-md-row w-100 align-items-center">
          <div>
            {" "}
            <Img className="mb-3" fixed={iconImg}></Img>
          </div>
          <div className="d-flex flex-column ml-4">
            <LocalizedLink to={programHref} className="text-decoration-none">
              <h5 className="text-primary" style={{ fontSize: 22 }}>
                {header}
              </h5>
            </LocalizedLink>
            <h5 className="text-dark" style={{ fontSize: 18 }}>
              {shortDescription}
            </h5>
            <span className="text-secondary">
              <strong>{sonSurum}: </strong>
              {lastVersion}
            </span>
            <span className="text-secondary">
              <strong>{yayinlanmaTarihi}: </strong>
              {publishDate}
            </span>
          </div>
          <div
            className="d-flex flex-column ml-auto mr-auto mt-3 mt-md-0 mr-md-0 align-items-center align-items-md-end"
            style={{ width: 310 }}
          >
            <div className="d-flex mb-3">
              {buyHref && (
                <LocalizedLink
                  to={buyHref}
                  role="button"
                  className={`btn btn-primary py-2 font-weight-bold ${
                    downloadHref && "mr-2"
                  }`}
                  style={{ width: "150px" }}
                >
                  {buyText || "Lisans Al"}
                </LocalizedLink>
              )}
              {downloadHref && (
                <LocalizedLink
                  to={downloadHref}
                  role="button"
                  className="btn btn-success py-2 font-weight-bold"
                  style={{ width: "150px" }}
                >
                  {indir}
                </LocalizedLink>
              )}
            </div>
            <small className="align-self-start">
              <Img className="mr-2" fixed={miniComputerIcon} />
              {specstext}
            </small>
          </div>
        </div>
      </Container>
      <div className="w-100 border"></div>
    </Container>
  )
}
IndirmeBox.propTypes = {
  iconImg: PropTypes.object,
  header: PropTypes.string,
  shortDescription: PropTypes.string,
  textHref: PropTypes.string,
  lastVersion: PropTypes.string,
  publishDate: PropTypes.string,
  programHref: PropTypes.string,
  buyHref: PropTypes.string,
  buyText: PropTypes.string,
  downloadHref: PropTypes.string,
  specstext: PropTypes.string,
  miniComputerIcon: PropTypes.object,
}

export default function Indirme({ data }) {
  const {
    genel: {
      kutuphaneProgrami,
      cekilisSitesi,
      cekilisProgrami,
      kutuphaneOtomasyonu,
      hizliCekilisSitesi,
      siteyeGit,
      cekilisYapmaYazilimi,
    },
    indirme: {
      seoTitle,
      seoDescription,
      kodvizittenIndir,
      uygundur1,
      uygundur2,
    },
  } = useTranslations()
  return (
    <Layout>
      <SEO
        title={seoTitle}
        description={seoDescription}
        pathname="https://www.kodvizit.com/indirme"
        datePublished="2017-02-08T18:26:13+00:00"
        dateModified="2020-07-17T16:53:13+00:00"
      />
      <Container style={{ paddingTop: 60, maxWidth: 800 }}>
        <h1 className="font-weight-light">
          <div dangerouslySetInnerHTML={{ __html: kodvizittenIndir }}></div>
        </h1>
      </Container>

      <IndirmeBox
        iconImg={data.tktIcon.childImageSharp.fixed}
        header={kutuphaneProgrami}
        shortDescription={kutuphaneOtomasyonu}
        lastVersion="3.6.0"
        publishDate="21.02.2020"
        programHref="/kutuphane-programi/"
        buyHref="/magaza/"
        downloadHref="/kutuphane-programi/indir/"
        specstext={uygundur1}
        miniComputerIcon={data.miniComputerIcon.childImageSharp.fixed}
      />
      <IndirmeBox
        iconImg={data.cekilisProgramIcon.childImageSharp.fixed}
        header={cekilisProgrami}
        shortDescription={cekilisYapmaYazilimi}
        lastVersion="4.1.7"
        publishDate="11.02.2014"
        programHref="/cekilis-programi/"
        downloadHref="/cekilis-programi/indir"
        specstext={uygundur2}
        miniComputerIcon={data.miniComputerIcon.childImageSharp.fixed}
      />
      <IndirmeBox
        iconImg={data.cekilisSiteIcon.childImageSharp.fixed}
        header={cekilisSitesi}
        shortDescription={hizliCekilisSitesi}
        lastVersion="1.0.0"
        publishDate="5.12.2016"
        programHref="/cekilis-sitesi/"
        buyHref="/cekilis/"
        buyText={siteyeGit}
        specstext={uygundur1}
        miniComputerIcon={data.miniComputerIcon.childImageSharp.fixed}
      />
    </Layout>
  )
}

export const query = graphql`
  query {
    tktIcon: file(relativePath: { eq: "icon/tkticon72.png" }) {
      childImageSharp {
        fixed(width: 72, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    cekilisProgramIcon: file(
      relativePath: { eq: "icon/cekilisprogram72.png" }
    ) {
      childImageSharp {
        fixed(width: 72, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    cekilisSiteIcon: file(relativePath: { eq: "icon/cekilissite72.png" }) {
      childImageSharp {
        fixed(width: 72, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    miniComputerIcon: file(relativePath: { eq: "icon/mini_computer.png" }) {
      childImageSharp {
        fixed(width: 16, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`

Indirme.propTypes = {
  data: PropTypes.object,
}
